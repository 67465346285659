<template>
<div>
    <div class="leads-table-area addcustomer-table">
        <table id="payment_table" class="display">
            <thead>
                <tr>
                    <th>Booking Ref-id</th>
                    <th>Amount</th>
                    <th>Arrival Date</th>
                    <th>Processing Date</th>
                    <th>Status</th>
                    
                    
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>

</div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
//import moment from "moment";

export default {
    name: "stripePayment",
    setup () {
        const toast = useToast();
        return { toast }
    },
    mounted() {
        let thiss = this;
        $("#payment_table")
        //.addClass("nowrap")
        .dataTable({
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_payment_history",
                type: "POST",
                data: function (d) {
                    d.business_id = thiss.$storage.getStorageSync('business').active_business.id
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                }
            },
            searching: false,
            drawCallback: function (response) {
                console.log(response.json.stats)
            },
            columnDefs: [
                {
                    targets: [2, 4],
                    orderable: false,
                },
            ]
        });
    },
};
</script>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
<style>
.lead-search .date-controll{
    background-color:#fff !important;
    padding-left: 30px;
}
.dp__main{
    width: 100%;
}
.dp__input_icon{
    left: 13px;
}
.dp__clear_icon{
    right: -15px;
}
.counter-num h4{
    font-size: 25px;
}
</style>