<template>
    <AppContainer>
        <StripePayment/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import StripePayment from '@/components/dashboard/stripe/stripePayment.vue'
export default {
  name: 'stripePayment',
  components: {
    AppContainer,
    StripePayment
  }
}
</script>